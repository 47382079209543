import { useNavigate, useRouteError } from '@remix-run/react'
import { useEffect } from 'react'
import { logtail } from '~/utils/logging.client.ts'

export function GeneralErrorBoundary() {
  const error = useRouteError()
  const navigate = useNavigate()
  logtail?.error(`Error boundary:`)
  logtail?.error(error as Error)

  useEffect(() => {
    if (error) {
      navigate('/', { replace: true })
    }
  }, [error, navigate])

  return null
}
